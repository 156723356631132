import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";

const SurveyTab = EgretLoadable({
  loader: () => import("./SurveyTab")
});

const surveyRoutes = [
  {
    path: "/surveys/sampleSurvey",
    component: SurveyTab,
    // auth: authRoles.admin
  },
];


export default surveyRoutes;
